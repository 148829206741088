<template>
    <div id="page-ip-manager-vrfs" class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="heading">VRFs</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <app-table
                    class="vrfs-table"

                    :cols="cols"
                    :rows="vrfs"

                    :sort-value="filter.sort"
                    :sort-options="sort"
                    @sort-change="onSortChange"

                    :loading="loading"
                />

                <app-pagination
                    v-if="pagination.total"

                    v-model="pagination.page"

                    :total="pagination.total"
                    :limit="pagination.limit"
                    :page-range="pagination.range"

                    @change="onPageChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

export default {
    components: {
        appTable,
        appPagination,
    },
    
    data() {
        return {
            vrfs: [],

            filter: {
                sort: {
                    field: 'Name',
                    order: 'asc'
                },
            },

            sort: [
                { field: 'Name',      title: 'VRF name' },
                { field: 'Prefix',    title: 'Prefix'    },
                { field: 'Allocated', title: 'Allocated' },
            ],

            cols: [
                { key: 'name',      title: 'VRF name', sort: { field: 'Name'      } },
                { key: 'prefix',    title: 'Prefix',    sort: { field: 'Prefix'    } },
                { key: 'allocated', title: 'Allocated', sort: { field: 'Allocated' } },
            ],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            loading: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getPools()
        },

        getPools(page) {
            this.loading = true
            
            setTimeout(() => {
                const vrfs = []

                for (let i = 0; i < this.pagination.limit; i++) {
                    vrfs.push({
                        name: 'Pool Name',
                        prefix: 'Lorem ipsum dolor sit amet, consectetur ',
                        allocated: 'Dolore magna aliqua',
                    })
                }

                this.vrfs = vrfs

                this.pagination.total = this.pagination.limit

                this.loading = false
            }, 500)
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getPools()
        },

        onPageChange(page) {
            this.getPools(page)
        },
    },
}
</script>

<style lang="scss">
#page-ip-manager-vrfs {
    padding-bottom: 80px;
    
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .vrfs-table {
        @include table-cols-width((150px, 240px, 120px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    #page-ip-manager-vrfs {
        .vrfs-table {
            @include table-cols-width((150px, 240px, 150px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    #page-ip-manager-vrfs {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .vrfs-table {
            margin-bottom: 15px;

            @include table-cols-width-mobile((70px, 175px, 24px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>